import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import Config from '../config/config'
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt as faFilteAltRegular } from '@fortawesome/free-regular-svg-icons';
import { connect } from "react-redux";
import { getUser } from '../state/createStore';

export default function Embed(props) {

    const [embedData, setEmbedData] = useState(false);
    const [error, setError] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        getDocumentData();
    }, [])

    useEffect(() => {
        if(selected) {
            getEmbedData(selected);
        }
    }, [selected])

    function getEmbedData(id) {
        // Request API.
        axios.get(`${Config.apiURL}/embed/${props.subscriptionid}/${props.userid}/${props.domain}/${id}`, {
            contentType: 'text/html',
        }).then(res => {
            setEmbedData(res.data);
        }).catch(error => {
            // Handle error.
            setError(<p style={{textAlign:'center'}}>Nincs jogosultsága az adatok megtekintéséhez.</p>)
        });
    }

    function getDocumentData() {
        setDocuments([]);
        axios.get(`${Config.apiURL}/subscriptions/${props.subscriptionid}`).then(response => {

            if(response.data.subscription_status.id !== 1 || response.data.subscription_status.id !== 2) {
                axios.get(`${Config.apiURL}/subscription-types/${response.data.subscription_type}`).then(response => {
                    setDocuments(response.data.document_types);
                    if(response.data.document_types.length) {
                        setSelected(response.data.document_types[0].id);
                    }    
                }).catch(err => {
                    console.log('err', err);
                }); 
            }
            else {
                setError(<p style={{textAlign:'center'}}>Nincs jogosultsága az adatok megtekintéséhez.</p>)
            }

        }).catch(err => {
            console.log('err', err);
        });
    }

    useEffect(() => {        

        let paragraphTitle = document.querySelectorAll("#paragraph-title");
        let titleList = document.querySelector(".title-list");

        titleList.innerHTML = '';
        paragraphTitle.forEach(title => {
            let listItem = document.createElement("li");
            listItem.className = "sidebar-paragraph-title";
            listItem.innerHTML = title.innerHTML;
            titleList.appendChild(listItem);
            
            listItem.addEventListener("click", function() {
                let titlePosition = title.offsetTop + 20;
                window.scrollTo({top: titlePosition, behavior: 'smooth'})
            })
        })
        
    }, [embedData] );   

    return(
        <div>
            {!error ? 
            <div>
                <div className="top-container">
                    {documents.map(doc => {
                        return(
                            <div className="document-item" onClick={() => setSelected(doc.id)} style={{fontSize:'15px'}}>
                                <button disabled={doc.id === selected} className={selected === doc.id ? "active" : ""}><FontAwesomeIcon icon={faFileAlt}/>{doc.Name}</button>
                            </div>
                        );
                    })}
                </div>
                <div className="bottom-container d-md-flex justify-content-end">
                    <div className="sidebar col-md-3">
                        <ul className="title-list" style={{fontSize: '14px', overflow: "auto", maxHeight: "600px"}}>
                        </ul>
                    </div>
                    <div className="content col-md-9">
                        {embedData && parse(embedData)}
                    </div>
                </div>
            </div>
            :
            error}
        </div>
    )
}